import axios from 'axios';
import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import debounce from 'lodash/debounce';
import pluralize from 'pluralize';
import React, {
  type FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Controller,
  FormProvider,
  get,
  useForm,
  useFormContext,
  useFormState,
  type UseFormWatch,
  useWatch,
} from 'react-hook-form';
import Select, { type SingleValue } from 'react-select';
import { type ITimezoneOption } from 'react-timezone-select';
import useSWRImmutable from 'swr/immutable';

import { useEventSchedulerAnalytics } from '../../analytics/eventScheduler';
import config from '../../config';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useTimezone } from '../../hooks/useTimezone';
import { apiService } from '../../services/api-service';
import {
  type EventType,
  type Host,
  type Producer,
} from '../../services/api-service/event.api';
import { type Organizer } from '../../types';
import { type GamePack, type PlayerRange } from '../../types/game';
import { fromDTOGamePack } from '../../utils/api-dto';
import { err2s } from '../../utils/common';
import { type EventTime } from '../../utils/date';
import { buildReactSelectStyles } from '../../utils/react-select';
import { TimeUtils } from '../../utils/time';
import { IconCopyButton } from '../common/CopyButton';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { PlayerRangeUtils } from '../Game/PlayerRangeUtils';
import { GamePackCover } from '../Game/Utilities';
import { CalendarIcon } from '../icons/CalendarIcon';
import { ChatBubbleIcon } from '../icons/Chat/ChatBubbleIcon';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { EmailIcon2 } from '../icons/EmailIcon';
import { GameIcon } from '../icons/GameIcon';
import { HostIcon } from '../icons/HostIcon';
import { TeamIcon } from '../icons/TeamIcon';
import { TimerIcon } from '../icons/TimerIcon';
import { Loading } from '../Loading';
import {
  OrganizationSelect,
  OrgSubscriptionUpgradeIcon,
} from '../Organization';
import { useMyOrganizationFeatureChecker } from '../Organization/hooks/organization';
import { OrganizerPicker } from '../Organization/OrganizerPicker';
import { OrganizerMultiSelect } from '../Organization/OrganizerSelect';
import { type OrganizerSelectOption } from '../Organization/OrganizerSelectGroup';
import { useTriggerBookNow } from '../Product/BookNow';
import { ReactTimezoneSelectTypeFixed } from '../ReactTimezoneSelectTypeFixed';
import { SwitcherControlled } from '../Switcher';
import { getVenueSlug } from '../Venue/VenueProvider';
import { EventEditorAttendeesField } from './EventEditorAttendees';
import { EventGamePackPicker } from './EventGamePackPicker';
import { formatTypeformSlug } from './utils';

const MESSAGE_LIMIT = 1000;

export type EventEditorData = {
  gamePackId: string | null;
  eventTime: EventTime;
  organizerUid: string | null;
  attendees: OrganizerSelectOption[];
  type: EventType;
  durationMin: number;
  message: string;
  // live game fields
  prepMin?: number;
  studioOption: StudioOption | null;
  hostUid?: string;
  producerUid?: string;
  venueOption: VenueOption | null;
  orgName?: string;
  organizerName?: string;
  organizerEmail?: string;
  attendeeEmails?: string;
  eventTitle?: string;
  eventFirstParagraph?: string;
  hostShoutOut?: string;
  vipOnStage: boolean;
  sendLiveNotify?: boolean;
  // returned
  orgId: string | null;
  gamePack?: GamePack;
  organizer?: Organizer;
  host?: Host;
  producer?: Producer;
};

function defaultEventTime(): EventTime {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const recommended = TimeUtils.RoundToNearest(new Date(), 30);

  return {
    startDate: zonedTimeToUtc(recommended, timezone),
    timezone,
  };
}

async function loadGamePack(gamePackId: string): Promise<GamePack | undefined> {
  try {
    const resp = await apiService.gamePack.getGamePackById(gamePackId);
    return fromDTOGamePack(resp.data.gamePack);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 404) {
        return undefined;
      }
    }
    throw e;
  }
}

function GamePackSelector(props: {
  gamePackId: string | null;
  onChange: (gamePackId: string) => void;
  setGamePackPlayerRange: (playerRange: PlayerRange) => void;
  setCanAccessGamePack: (hasAccess: boolean) => void;
  setSeatCapLimit: (seatCap: number | undefined) => void;
  featured: boolean;
  orgName?: string;
  title?: string;
  modalTitle?: string;
  modalTips?: string;
  modalClassName?: string;
}): JSX.Element | null {
  const featureChecker = useMyOrganizationFeatureChecker();
  const { data, error, isValidating } = useSWRImmutable(
    props.gamePackId ? ['/game-packs/', props.gamePackId] : null,
    ([_, gamePackId]) => loadGamePack(gamePackId),
    {
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (!data) return;
    props.setGamePackPlayerRange(data.playerRange);
    props.setCanAccessGamePack(featureChecker.canAccessGamePackForOnd(data));
    props.setSeatCapLimit(featureChecker.getVenueCap(data));
  }, [data, featureChecker, props]);

  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const onSwitch = async () => {
    await confirmCancel({
      kind: 'custom',
      containerClassName: 'bg-none',
      element: ({ internalOnCancel, internalOnConfirm }) => (
        <div className='fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center'>
          <div
            className={`${
              props.modalClassName ?? 'w-3/4'
            } border border-secondary rounded-xl px-20 py-12 bg-modal`}
          >
            <EventGamePackPicker
              featured={props.featured}
              onCancel={internalOnCancel}
              onSelect={(gamePack) => {
                props.setGamePackPlayerRange(gamePack.playerRange);
                props.setCanAccessGamePack(
                  featureChecker.canAccessGamePackForOnd(gamePack)
                );
                props.setSeatCapLimit(featureChecker.getVenueCap(gamePack));
                props.onChange(gamePack.id);
                internalOnConfirm();
              }}
              modalTitle={props.modalTitle}
              modalTips={props.modalTips}
            />
          </div>
        </div>
      ),
    });
  };

  if (!data && isValidating) return <Loading text='' />;
  if (!data && error) {
    return (
      <div className='text-secondary text-xs text-center'>
        Failed to load Game Pack. Try reloading.
      </div>
    );
  }

  if (!props.gamePackId || !data) {
    return (
      <button
        type='button'
        onClick={onSwitch}
        className='btn-primary w-full h-20 text-base flex flex-col items-center justify-center gap-2'
      >
        <GameIcon />
        <p>{`${props.title ?? 'Choose a Game'}`}</p>
      </button>
    );
  }

  const showUpgradeIcon = !featureChecker.canAccessGamePackForOnd(data);
  const durationSec = Math.round(data.approximateDurationSeconds / 60);
  return (
    <div className='w-full flex justify-center items-start gap-6 md:flex-col-reverse'>
      <div className='w-full h-full flex flex-col flex-grow text-white gap-2'>
        <div className='flex items-center gap-2'>
          <div className='font-bold truncate'>{data.name}</div>
          {showUpgradeIcon && (
            <div className='flex-none'>
              <OrgSubscriptionUpgradeIcon />
            </div>
          )}
        </div>
        {showUpgradeIcon && (
          <div className='text-sm text-tertiary'>
            Scheduling this game requires upgrading your account.
          </div>
        )}
        <div className='text-sm text-icon-gray'>{data.description}</div>
        <div className='w-full mt-2 flex items-center gap-1 text-xs text-icon-gray'>
          <span>
            {durationSec} {pluralize('Minute', durationSec)}
          </span>
          <span>·</span>
          <span>{PlayerRangeUtils.Format(data.playerRange)}</span>
        </div>
        <span
          className='cursor-pointer text-xs text-primary'
          onClick={onSwitch}
        >
          Switch Game
        </span>
      </div>

      <div className='w-1/3 md:w-full flex-none'>
        <GamePackCover pack={data} />
      </div>
    </div>
  );
}

function StartTimePicker(props: {
  initialValue?: EventEditorData['eventTime'];
  onChange: (eventTime: EventTime) => void;
}): JSX.Element {
  const { startDate, timezone } = props.initialValue ?? defaultEventTime();
  const localTimeZone = useTimezone();
  const localizedStartDate = useMemo(() => {
    const selected = zonedTimeToUtc(startDate, timezone);
    const user = zonedTimeToUtc(startDate, localTimeZone);
    return selected.getTime() !== user.getTime() ? user : undefined;
  }, [startDate, timezone, localTimeZone]);

  const styles = useMemo(
    () =>
      buildReactSelectStyles<ITimezoneOption>({
        override: {
          control: { height: '40px' },
        },
      }),
    []
  );

  return (
    <div>
      <div className='flex items-center text-white'>
        <div>
          <ReactDatePicker
            className='field h-10 mb-0'
            selected={utcToZonedTime(startDate, timezone)}
            onChange={(change) => {
              if (!change) return;
              props.onChange({
                startDate: zonedTimeToUtc(change, timezone),
                timezone,
              });
            }}
            minDate={new Date()}
            dateFormat='MMMM d, yyyy'
          />
        </div>

        <div className='mx-4'>at</div>

        <div>
          <ReactDatePicker
            className='relative field h-10 mb-0 flex-none w-24 text-center'
            selected={utcToZonedTime(startDate, timezone)}
            onChange={(change) => {
              if (!change) return;
              // note: there seems to be a bug in the library where manually inputting the time will reset the day.
              // as a workaround, we manually set the time here.
              const date = utcToZonedTime(startDate, timezone);
              date.setHours(change.getHours());
              date.setMinutes(change.getMinutes());
              date.setSeconds(0);
              date.setMilliseconds(0);

              props.onChange({
                startDate: zonedTimeToUtc(date, timezone),
                timezone,
              });
            }}
            minDate={new Date()}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat='h:mm aa'
          />
        </div>

        <div className='hidden md:flex ml-2'>
          <ReactTimezoneSelectTypeFixed
            value={timezone}
            className='select-box text-white'
            classNamePrefix='select-box-v2'
            styles={styles}
            onChange={(change) => {
              if (!change) return;
              props.onChange({
                startDate: zonedTimeToUtc(
                  utcToZonedTime(startDate, timezone),
                  change.value
                ),
                timezone: change.value,
              });
            }}
          />
        </div>
      </div>

      {localizedStartDate && (
        <div className='mt-2 text-xs text-secondary'>
          For your local time zone that‘s:{' '}
          {format(localizedStartDate, 'MMMM d, yyyy h:mm aa')}
        </div>
      )}
    </div>
  );
}

type DurationOption = {
  label: string;
  value: number;
};

const ondDurationOptions: DurationOption[] = [15, 30, 45, 60].map((min) => ({
  label: `${min} minutes`,
  value: min,
}));

const liveDurationOptions: DurationOption[] = [30, 45, 60, 90, 120].map(
  (min) => ({
    label: `${min} minutes`,
    value: min,
  })
);

const prepTimeOptions: DurationOption[] = [15, 20, 30, 45, 60].map((min) => ({
  label: `${min} minutes`,
  value: min,
}));

function EventDurationPicker(props: {
  initialValue?: number;
  onChange: (durationMin: number) => void;
  durationOptions: DurationOption[];
}): JSX.Element {
  const { durationOptions } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<DurationOption>({
        override: {
          control: { height: '40px' },
        },
      }),
    []
  );

  const selected = !props.initialValue
    ? undefined
    : {
        label: `${props.initialValue} minutes`,
        value: props.initialValue,
      };

  return (
    <Select<DurationOption, false>
      options={durationOptions}
      defaultValue={durationOptions[1]}
      value={selected}
      styles={styles}
      classNamePrefix='select-box-v2'
      onChange={(change) => {
        if (!change) return;
        props.onChange(change.value);
      }}
    />
  );
}

function EventEditorButtons(props: {
  context: 'create' | 'edit';
  canSubmit: boolean;
  canAccessGamePack: boolean;
  exceedsSeatCapLimit: boolean;
  onCancel: () => void;
  showSendNotify?: boolean;
  sendNotify?: boolean;
  updateSendNotify?: (b: boolean) => void;
}): JSX.Element {
  const { isSubmitting, isValid, isDirty, isSubmitSuccessful } =
    useFormState<EventEditorData>();

  const [wasSubmitSuccessful, setWasSubmitSuccessful] = useState(false);
  const [sendNotify, setSendNotify] = useState(props.sendNotify ?? true);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setWasSubmitSuccessful(true);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (isDirty) {
      setWasSubmitSuccessful(false);
    }
  }, [isDirty]);

  return (
    <div className='flex flex-col md:flex-row items-center justify-center md:justify-between gap-6'>
      <div className='flex flex-row gap-6'>
        {props.showSendNotify && (
          <input
            type='checkbox'
            id='sendInviteEmails'
            className='w-5 h-5 checkbox-dark'
            checked={sendNotify}
            onChange={(e) => {
              if (props.updateSendNotify) {
                setSendNotify(e.target.checked);
                props.updateSendNotify(e.target.checked);
              }
            }}
          />
        )}
        {props.showSendNotify && (
          <div className='text-white'> Send update email to organizer</div>
        )}
      </div>
      <div className='flex flex-row gap-6'>
        <button
          type='button'
          className='btn btn-secondary w-40 h-10'
          disabled={isSubmitting}
          onClick={props.onCancel}
        >
          Cancel
        </button>
        {props.canSubmit && (
          <button
            type='submit'
            className='btn-primary w-40 h-10 flex items-center justify-center gap-2'
            disabled={
              !isValid ||
              isSubmitting ||
              (props.context === 'edit' && !isDirty) ||
              !props.canAccessGamePack ||
              props.exceedsSeatCapLimit
            }
          >
            {isSubmitting ? (
              <Loading text='' />
            ) : wasSubmitSuccessful ? (
              <FilledCheckIcon />
            ) : null}
            {isSubmitting ? 'Saving' : wasSubmitSuccessful ? 'Saved' : 'Save'}
          </button>
        )}
      </div>
    </div>
  );
}

function defaultValues(
  eventType: EventType,
  initialData?: Partial<EventEditorData>
): EventEditorData {
  return {
    orgId: initialData?.orgId ?? null,
    orgName: initialData?.orgName,
    organizerUid: initialData?.organizerUid ?? null,
    gamePackId:
      initialData?.gamePackId ??
      (eventType === 'live' ? config.event.liveGamePack : null),
    eventTime: initialData?.eventTime ?? defaultEventTime(),
    durationMin: initialData?.durationMin ?? (eventType === 'live' ? 60 : 30),
    prepMin: initialData?.prepMin ?? 30,
    eventTitle: initialData?.eventTitle,
    eventFirstParagraph: initialData?.eventFirstParagraph,
    message: initialData?.message ?? '',
    attendees: initialData?.attendees ?? [],
    venueOption: initialData?.venueOption ?? null,
    studioOption: initialData?.studioOption ?? null,
    hostUid: initialData?.hostUid,
    producerUid: initialData?.producerUid,
    organizer: initialData?.organizer,
    type: initialData?.type ?? eventType,
    hostShoutOut: initialData?.hostShoutOut,
    vipOnStage: initialData?.vipOnStage ?? false,
  };
}

function useTrackFormModifications(watch: UseFormWatch<EventEditorData>) {
  const analytics = useEventSchedulerAnalytics();
  const trackMessageModified = useMemo(
    () =>
      debounce(
        () =>
          analytics.trackFormModified({ field: 'message', value: undefined }),
        500,
        { trailing: true }
      ),
    [analytics]
  );

  useEffect(() => {
    const sub = watch((values, { name }) => {
      if (!name) return;

      let value: unknown = undefined;
      switch (name) {
        case 'message':
          // note(falcon): we keep this information hidden, and debounce to avoid excessive events for each character
          // input in the form.
          trackMessageModified();
          return;
        case 'attendees':
          value = values.attendees
            ?.map((o) => (o?.kind === 'organizer' ? o.organizer?.uid : ''))
            .filter((o) => !!o);
          break;
        default:
          value = get(values, String(name), undefined);
          break;
      }

      analytics.trackFormModified({
        field: String(name),
        value,
      });
    });
    return () => sub.unsubscribe();
  }, [watch, analytics, trackMessageModified]);
}

function OnDemandEventsFields(props: {
  gamePackPlayerRange: PlayerRange | undefined;
  extraFormFields?: React.ReactNode[];
  seatCapLimit: number | undefined;
  presetEventTime?: boolean;
}): JSX.Element {
  const { gamePackPlayerRange, extraFormFields, seatCapLimit } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext<EventEditorData>();

  return (
    <div className='flex flex-col gap-10 mb-10'>
      <div>
        {props.presetEventTime && (
          <div className='text-green-001 text-sms pb-1'>
            <strong>Your date and time are set!</strong> You can change these at
            any time before your event.
          </div>
        )}
        <div className='flex items-start gap-3 text-white'>
          <div className='h-10 flex items-center justify-center'>
            <CalendarIcon className='fill-current w-5 h-5' />
          </div>
          <div className='flex-1'>
            <Controller<EventEditorData, 'eventTime'>
              name='eventTime'
              render={({ field: { onChange, value } }) => (
                <StartTimePicker onChange={onChange} initialValue={value} />
              )}
            />
          </div>
        </div>
      </div>

      <div className='flex items-center gap-3 text-white'>
        <TimerIcon className='fill-current w-5 h-5' />
        <div className='w-35'>
          <Controller<EventEditorData, 'durationMin'>
            name='durationMin'
            render={({ field: { onChange, value } }) => (
              <EventDurationPicker
                onChange={onChange}
                initialValue={value}
                durationOptions={ondDurationOptions}
              />
            )}
          />
        </div>
      </div>

      <EventEditorAttendeesField
        gamePackPlayerRange={gamePackPlayerRange}
        seatCapLimit={seatCapLimit}
      />

      <div className='flex items-start gap-3 text-white'>
        <div className='h-10 flex items-center justify-center'>
          <ChatBubbleIcon className='fill-current w-5 h-5' />
        </div>
        <div className='w-full flex flex-col'>
          <textarea
            className={`
                  flex h-24 py-2 px-2.5 resize-none mb-0 scrollbar
                  ${errors.message !== undefined ? 'field-error' : 'field'}
                `}
            placeholder='Add message to attendees (optional)'
            {...register('message', {
              maxLength: MESSAGE_LIMIT,
            })}
          />
          <div
            className={`mt-2 text-xs self-end ${
              errors.message !== undefined ? 'text-red-002' : 'text-secondary'
            }`}
          >
            Max {MESSAGE_LIMIT} characters
          </div>
        </div>
      </div>
      {extraFormFields}
    </div>
  );
}

type HostOption = {
  label: string;
  value: string;
};

type ProducerOption = {
  label: string;
  value: string;
};

type StudioOption = {
  label: string;
  value: string;
};

type VenueOption = {
  label: string;
  value: string;
};

const emptyHost: Host = {
  name: 'Not Yet Scheduled',
  uid: '00000000-0000-0000-0000-000000000000',
  email: '',
  active: true,
};

export const emptyProducer: Host = {
  name: 'Not Yet Scheduled',
  uid: '00000000-0000-0000-0000-000000000000',
  email: '',
  active: true,
};

function HostSelect(props: {
  onChange: (host: Host) => void;
  hostId?: string;
}): JSX.Element {
  const { data, isValidating, error } = useSWRImmutable(
    '/events/live-hosts',
    async () => (await apiService.event.liveHosts()).data,
    {
      shouldRetryOnError: false,
    }
  );

  const style = useMemo(
    () =>
      buildReactSelectStyles<HostOption>({
        override: {
          placeholder: {
            color: '#697176',
          },
        },
      }),
    []
  );

  const options = useMemo(() => {
    if (isValidating || !data || !data.hosts) return [];
    const opts = data.hosts.map((o) => ({
      label: o.name,
      value: o.uid,
    }));
    opts.sort((a, b) => a.label.localeCompare(b.label));
    return [{ label: emptyHost.name, value: emptyHost.uid }, ...opts];
  }, [data, isValidating]);

  const onHostChange = (option: SingleValue<HostOption>) => {
    if (!option || !data) return;
    const host = [emptyHost, ...data.hosts].find((h) => h.uid === option.value);
    if (host) props.onChange(host);
  };

  if (isValidating) {
    return <Loading containerClassName='justify-start' />;
  }

  if (error) {
    return <div className='text-3xs text-red-002'>{err2s(error)}</div>;
  }

  return (
    <Select<HostOption>
      placeholder='Select host'
      className='text-white'
      options={options}
      onChange={onHostChange}
      styles={style}
      value={
        props.hostId
          ? options.find((opt) => opt.value === props.hostId)
          : undefined
      }
    />
  );
}

function ProducerSelect(props: {
  onChange: (producer: Producer) => void;
  producerId?: string;
  cohostedEnabled?: boolean;
}): JSX.Element {
  const { data, isValidating, error } = useSWRImmutable(
    '/events/live-producers',
    async () => (await apiService.event.liveProducers()).data,
    {
      shouldRetryOnError: false,
    }
  );

  const style = useMemo(
    () =>
      buildReactSelectStyles<ProducerOption>({
        override: {
          placeholder: {
            color: '#697176',
          },
        },
      }),
    []
  );

  const producers = useMemo(() => {
    const sorted = (data?.producers ?? []).sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    if (props.cohostedEnabled) {
      return [
        {
          ...emptyProducer,
          name: 'N/A',
        },
        ...sorted,
      ];
    } else {
      return [emptyProducer, ...sorted];
    }
  }, [data?.producers, props.cohostedEnabled]);

  const options = useMemo(() => {
    const opts = producers.map((o) => ({ label: o.name, value: o.uid }));
    return opts;
  }, [producers]);

  const onProducerChange = (option: SingleValue<ProducerOption>) => {
    if (!option || !data) return;
    const producer = producers.find((h) => h.uid === option.value);
    if (producer) props.onChange(producer);
  };

  if (isValidating) {
    return <Loading containerClassName='justify-start' />;
  }

  if (error) {
    return <div className='text-3xs text-red-002'>{err2s(error)}</div>;
  }

  return (
    <Select<ProducerOption>
      placeholder='Select producer'
      className='text-white'
      options={options}
      onChange={onProducerChange}
      styles={style}
      value={
        props.producerId
          ? options.find((opt) => opt.value === props.producerId)
          : undefined
      }
    />
  );
}

const noStudioOption: StudioOption = {
  label: 'No Studio',
  value: '00000000-0000-0000-0000-000000000000',
};

function StudioSelect(props: {
  onChange: (studio: StudioOption | null) => void;
  studioOption?: StudioOption | null;
  extraOptions?: StudioOption[];
}): JSX.Element {
  const { data, isValidating, error } = useSWRImmutable(
    '/events/live-studios',
    async () => (await apiService.event.liveStudio()).data,
    {
      shouldRetryOnError: false,
    }
  );

  const style = useMemo(
    () =>
      buildReactSelectStyles<StudioOption>({
        override: {
          placeholder: {
            color: '#697176',
          },
        },
      }),
    []
  );

  const options = useMemo(() => {
    if (isValidating || !data || !data.studios) return [];
    const seen = new Set<string>();
    const opts: StudioOption[] = [];
    for (const studio of data.studios) {
      opts.push({ label: studio.name, value: studio.id });
      seen.add(studio.id);
    }
    for (const opt of props.extraOptions ?? []) {
      if (!seen.has(opt.value)) {
        opts.push(opt);
        seen.add(opt.value);
      }
    }
    opts.sort((a, b) => a.label.localeCompare(b.label));
    return opts;
  }, [data, isValidating, props.extraOptions]);

  const onStudioChange = (option: SingleValue<StudioOption>) => {
    props.onChange(option);
  };

  if (isValidating) {
    return <Loading containerClassName='justify-start' />;
  }

  if (error) {
    return <div className='text-3xs text-red-002'>{err2s(error)}</div>;
  }

  return (
    <Select<StudioOption>
      placeholder='Select Studio'
      className='text-white'
      options={options}
      onChange={onStudioChange}
      styles={style}
      value={props.studioOption}
    />
  );
}

export const createNewVenueOption: VenueOption = {
  label: 'Create New Venue',
  value: '00000000-0000-0000-0000-000000000000',
};

function VenueSelect(props: {
  onChange: (venue: VenueOption | null) => void;
  venueOption?: VenueOption | null;
  extraOptions?: VenueOption[];
}): JSX.Element {
  const { data, isValidating, error } = useSWRImmutable(
    '/events/live-venues',
    async () => (await apiService.event.liveVenues()).data,
    {
      shouldRetryOnError: false,
    }
  );

  const style = useMemo(
    () =>
      buildReactSelectStyles<VenueOption>({
        override: {
          placeholder: {
            color: '#697176',
          },
        },
      }),
    []
  );

  const options = useMemo(() => {
    if (isValidating || !data || !data.venues) return [];
    const seen = new Set<string>();
    const opts: VenueOption[] = [];
    for (const venue of data.venues) {
      opts.push({ label: venue.name ?? venue.id, value: venue.id });
      seen.add(venue.id);
    }
    for (const opt of props.extraOptions ?? []) {
      if (!seen.has(opt.value)) {
        opts.push(opt);
        seen.add(opt.value);
      }
    }
    return opts;
  }, [data, isValidating, props.extraOptions]);

  const onVenueChange = (option: SingleValue<VenueOption>) => {
    props.onChange(option);
  };

  if (isValidating) {
    return <Loading containerClassName='justify-start' />;
  }

  if (error) {
    return <div className='text-3xs text-red-002'>{err2s(error)}</div>;
  }

  return (
    <Select<VenueOption>
      placeholder='Select venue'
      className='text-white'
      options={options}
      onChange={onVenueChange}
      styles={style}
      value={props.venueOption}
    />
  );
}

function HostUrlView(props: { eventId: string }): JSX.Element {
  const [orgName, startDate, venueOption, gamePackId] = useWatch<
    EventEditorData,
    ['orgName', 'eventTime.startDate', 'venueOption', 'gamePackId']
  >({
    name: ['orgName', 'eventTime.startDate', 'venueOption', 'gamePackId'],
  });

  const { data: gamePack } = useSWRImmutable(
    gamePackId ? ['/game-packs/', gamePackId] : null,
    ([_, gamePackId]) => loadGamePack(gamePackId),
    {
      shouldRetryOnError: false,
    }
  );

  const typeformSlug = formatTypeformSlug(
    orgName ?? 'unknown',
    startDate.toISOString()
  );

  const hostUrl = useMemo(() => {
    if (!venueOption || venueOption.value === createNewVenueOption.value)
      return 'unknown venue';

    if (gamePack?.cohostSettings?.enabled) {
      return `${window.location.origin}/events/${props.eventId}/cohost`;
    }

    const venueSlug = getVenueSlug({
      venueId: venueOption.value,
      venueName: venueOption.label,
    });
    const params = new URLSearchParams();
    params.set('event-id', props.eventId);
    params.set('chat-private-channel', 'enabled');
    params.set('organization', typeformSlug);

    return `${
      window.location.origin
    }/host/venue/${venueSlug}?${params.toString()}`;
  }, [props.eventId, typeformSlug, venueOption, gamePack]);

  return (
    <div className='flex text-white gap-4'>
      <div className='flex-1'>
        <div className='text-sm pb-1 flex items-center gap-2'>
          Host URL
          <IconCopyButton text={hostUrl} />
        </div>
        <input readOnly className='w-full h-10 field mb-0' value={hostUrl} />
      </div>
      <div className='flex-1'>
        <div className='text-sm pb-1 flex items-center gap-2'>
          Typeform Slug
          <IconCopyButton text={typeformSlug} />
        </div>
        <input
          readOnly
          className='w-full h-10 field mb-0'
          value={typeformSlug}
        />
      </div>
    </div>
  );
}

function LiveEventsFields(props: {
  isSubscriber: boolean;
  extraFormFields?: React.ReactNode[];
  initialData?: Partial<EventEditorData>;
  context: 'create' | 'edit';
  eventId?: string;
}): JSX.Element {
  const { extraFormFields, isSubscriber, context } = props;

  const { getValues, register, setValue, watch } =
    useFormContext<EventEditorData>();

  const orgId = watch('orgId');
  const gamePackId = watch('gamePackId');
  const organizerUid = watch('organizerUid');

  const { data: gamePack } = useSWRImmutable(
    gamePackId ? ['/game-packs/', gamePackId] : null,
    ([_, gamePackId]) => loadGamePack(gamePackId),
    {
      shouldRetryOnError: false,
    }
  );

  const extraVenueOptions = useMemo(() => {
    const opts = [];
    if (props.initialData?.venueOption) {
      opts.push(props.initialData.venueOption);
    }
    if (gamePack?.cohostSettings?.enabled) {
      opts.push(createNewVenueOption);
    }
    return opts;
  }, [props.initialData?.venueOption, gamePack?.cohostSettings?.enabled]);

  const extraStudioOptions = useMemo(() => {
    const opts = [];
    if (gamePack?.cohostSettings?.enabled) {
      opts.push(noStudioOption);
    }
    return opts;
  }, [gamePack?.cohostSettings?.enabled]);

  return (
    <div className='flex flex-col gap-10 mb-10'>
      {isSubscriber && (
        <>
          <div className='flex flex-row gap-3 items-center'>
            <TeamIcon className='fill-current w-5 h-5 text-white' />
            <div className='w-full'>
              <Controller<EventEditorData, 'orgId'>
                name='orgId'
                render={({ field: { onChange, value } }) => {
                  return (
                    <OrganizationSelect
                      isDisabled={context === 'edit'}
                      placeholder='Select the org'
                      className='w-full'
                      orgId={value}
                      onChange={(org) => {
                        onChange(org?.id);
                        setValue<'orgName'>('orgName', org?.name);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className='flex flex-row gap-3 items-center'>
            <EmailIcon2 className='fill-current w-5 h-5 text-white' />
            <div className='w-full'>
              <Controller<EventEditorData, 'organizerUid'>
                name='organizerUid'
                render={({ field: { onChange } }) => {
                  return (
                    <OrganizerPicker
                      isDisabled={context === 'edit'}
                      orgId={orgId}
                      onChange={(organizer) => {
                        onChange(organizer.uid);
                        setValue<'organizer'>('organizer', organizer);
                      }}
                      defaultOrganizer={getValues('organizer')}
                      placeholder={'Select member to send invite to'}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className='flex flex-row gap-3 items-center'>
            <EmailIcon2 className='fill-current w-5 h-5 text-white' />
            <div className='w-full'>
              <Controller<EventEditorData, 'attendees'>
                name='attendees'
                render={({ field: { value, onChange } }) => {
                  return (
                    <OrganizerMultiSelect
                      orgId={orgId || ''}
                      options={
                        value.filter(
                          (v) => v.kind === 'organizer' || v.kind === undefined
                        ) as never
                      }
                      onChange={onChange}
                      filterOrganizer={(o) => o.uid !== organizerUid}
                      placeholder='Select other org members to send invite to'
                    />
                  );
                }}
              />
            </div>
          </div>
        </>
      )}

      {!isSubscriber && (
        <>
          <div className='flex flex-row gap-3 items-center justify-center'>
            <TeamIcon className='fill-current w-5 h-5 text-white' />
            <input
              className={`w-full h-10 field mb-0`}
              placeholder='Enter the organization name'
              {...register('orgName', {
                required: false,
              })}
              defaultValue={props.initialData?.orgName}
              disabled={context === 'edit'}
            ></input>
          </div>

          <div className='flex flex-row gap-3 items-center'>
            <EmailIcon2 className='fill-current w-5 h-5 text-white' />
            <div className='w-full flex flex-row gap-3'>
              <input
                className={`w-1/2 h-9.5 field mb-0`}
                placeholder='Invitee Full Name'
                {...register('organizerName', {
                  required: false,
                })}
                defaultValue={props.initialData?.organizerName}
                // disabled={context === 'edit'}
              ></input>
              <input
                type='email'
                className={`w-1/2 h-9.5 field mb-0`}
                placeholder='Invitee Email Address'
                {...register('organizerEmail', {
                  required: false,
                })}
                defaultValue={props.initialData?.organizerEmail}
                disabled={context === 'edit'}
              ></input>
            </div>
          </div>
          <div className='flex flex-row gap-3 items-center'>
            <EmailIcon2 className='fill-current w-5 h-5 text-white' />
            <div className='w-full flex flex-row gap-3'>
              <input
                type='email'
                className={`w-full h-9.5 field mb-0`}
                placeholder='Another Invitee Email Address'
                {...register('attendeeEmails', {
                  required: false,
                })}
                defaultValue={props.initialData?.attendeeEmails}
              ></input>
            </div>
          </div>
        </>
      )}

      <div className='flex flex-row items-center gap-3'>
        <HostIcon className='fill-current w-5 h-5 text-white' />
        <div className='w-1/4'>
          <Controller<EventEditorData, 'hostUid'>
            name='hostUid'
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <HostSelect
                onChange={(host) => {
                  onChange(host.uid);
                  setValue<'host'>('host', host);
                }}
                hostId={value}
              />
            )}
          />
        </div>
        <div className='w-1/4'>
          <Controller<EventEditorData, 'studioOption'>
            name='studioOption'
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StudioSelect
                onChange={onChange}
                studioOption={value}
                extraOptions={extraStudioOptions}
              />
            )}
          />
        </div>
        <div className='w-1/4'>
          <Controller<EventEditorData, 'venueOption'>
            name='venueOption'
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <VenueSelect
                onChange={onChange}
                venueOption={value}
                extraOptions={extraVenueOptions}
              />
            )}
          />
        </div>
        <div className='w-1/4'>
          <Controller<EventEditorData, 'producerUid'>
            name='producerUid'
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <ProducerSelect
                onChange={(producer) => {
                  onChange(producer.uid);
                  setValue<'producer'>('producer', producer);
                }}
                producerId={value}
                cohostedEnabled={!!gamePack?.cohostSettings?.enabled}
              />
            )}
          />
        </div>
      </div>

      <div className='flex items-start gap-3 text-white'>
        <div className='h-10 flex items-center justify-center'>
          <CalendarIcon className='fill-current w-5 h-5' />
        </div>
        <div className='flex'>
          <Controller<EventEditorData, 'eventTime'>
            name='eventTime'
            render={({ field: { onChange, value } }) => (
              <StartTimePicker onChange={onChange} initialValue={value} />
            )}
          />
        </div>
      </div>

      <div className='flex flex-row items-center gap-3 text-white'>
        <TimerIcon className='fill-current w-5 h-5 ' />
        <div className='w-35'>
          <div className='pl-2 text-xs text-secondary'>Prep Time</div>
          <Controller<EventEditorData, 'prepMin'>
            name='prepMin'
            render={({ field: { onChange, value } }) => (
              <EventDurationPicker
                onChange={onChange}
                initialValue={value}
                durationOptions={prepTimeOptions}
              />
            )}
          />
        </div>
        <div className='w-35'>
          <div className='pl-2 text-xs text-secondary'>Game Duration</div>
          <Controller<EventEditorData, 'durationMin'>
            name='durationMin'
            render={({ field: { onChange, value } }) => (
              <EventDurationPicker
                onChange={onChange}
                initialValue={value}
                durationOptions={liveDurationOptions}
              />
            )}
          />
        </div>
      </div>

      <div className='flex flex-col text-white gap-1'>
        <div className='flex items-center justify-between'>
          <div className='h-5 text-sm'>Requested Host Shoutout</div>
          <div className='flex items-center gap-2'>
            <div className='font-bold text-sms'>Bring VIP on Stage</div>
            <Controller<EventEditorData, 'vipOnStage'>
              name='vipOnStage'
              render={({ field: { onChange, value } }) => {
                return (
                  <SwitcherControlled
                    name='hostShoutOut'
                    checked={value}
                    onChange={(checked) => {
                      onChange(checked);
                    }}
                  />
                );
              }}
            />
          </div>
        </div>

        <textarea
          {...register('hostShoutOut', {
            maxLength: 1000,
          })}
          className={`
            flex h-12 py-2 px-2.5 resize-none mb-0 scrollbar field
          `}
          placeholder='N/A'
          maxLength={1000}
        />
      </div>

      <div className='flex flex-col text-white gap-1'>
        <div className='h-5 text-sm'>External Event Title</div>
        <input
          className={`w-full h-10 field mb-0`}
          placeholder={`The World’s Greatest Virtual Event (Luna Park) for ${
            getValues('orgName') ?? ''
          }`}
          {...register('eventTitle', {
            required: false,
          })}
          defaultValue={props.initialData?.eventTitle}
        ></input>
      </div>
      <div className='flex flex-col items-start text-white gap-1'>
        <div className='h-5 text-sm'>External Event First Paragraph</div>
        <div className='w-full flex flex-col'>
          <textarea
            className={`
              flex h-24 py-2 px-2.5 resize-none mb-0 scrollbar field
            `}
            placeholder={
              'You are invited to The Luna Park Show! What the heck is the Luna Park Show? The world’s first live hosted game show for teams. Oh, and it’s NOT on Zoom. Luna Park is hosted by a professional comedian on a platform specifically designed to ensure this experience is awesome. If you hate it, we’ll give you your money back. Actually, you didn’t even pay. Just come. \nLuna Park is a semi-serious team based competition built around a series of (not so serious) mini-games.'
            }
            {...register('eventFirstParagraph', { required: false })}
            defaultValue={props.initialData?.eventFirstParagraph}
          />
        </div>
      </div>
      {props.context === 'edit' && props.eventId && (
        <HostUrlView eventId={props.eventId} />
      )}
      {extraFormFields}
    </div>
  );
}

export function EventEditor(props: {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  initialData?: Partial<EventEditorData>;
  onBeforeSubmit?: (data: EventEditorData) => Promise<boolean>;
  onSubmit: (data: EventEditorData) => Promise<void>;
  onCancel: () => void;
  extraFormFields?: React.ReactNode[];
  context: 'create' | 'edit';
  eventType: EventType;
  canSubmit?: boolean;
  eventId?: string;
}): JSX.Element {
  const form = useForm<EventEditorData>({
    mode: 'onChange',
    defaultValues: defaultValues(props.eventType, props.initialData),
  });

  const {
    reset,
    watch,
    setValue,
    formState: { isSubmitSuccessful },
  } = form;

  const organizer = watch('organizer');
  const orgName = watch('orgName');
  const attendees = watch('attendees');
  const gamePackId = watch('gamePackId');

  const triggerBookNow = useTriggerBookNow();

  // the editor does not have access to the gamepack which is nested in the GamePackSelector. as a workaround, we store
  // the gamepack player recommendation in this top level state, and pass it down to the attendee selector.
  const [gamePackPlayerRange, setGamePackPlayerRange] = useState<
    PlayerRange | undefined
  >(undefined);
  const [canAccessGamePack, setCanAccessGamePack] = useState<boolean>(false);
  const [seatCapLimit, setSeatCapLimit] = useState<number | undefined>(
    undefined
  );

  const [isSubscriber, setIsSubscriber] = useState(
    props.context === 'create' || props.initialData?.organizer ? true : false
  );

  useTrackFormModifications(watch);

  const hasTriggeredBookNow = useRef(false);
  useEffect(() => {
    if (!gamePackId || !seatCapLimit) return;
    // + 1 accounts for the organizer
    if (attendees.length + 1 > seatCapLimit && !hasTriggeredBookNow.current) {
      hasTriggeredBookNow.current = true;
      triggerBookNow(gamePackId, 'event-scheduler');
    }
  }, [gamePackId, attendees, triggerBookNow, seatCapLimit]);

  useEffect(() => {
    hasTriggeredBookNow.current = false;
  }, [gamePackId]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues(props.eventType, props.initialData));
    }
  }, [isSubmitSuccessful, reset, props.eventType, props.initialData]);

  const handleSubmit = useLiveCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (props.onBeforeSubmit) {
        const ok = await props.onBeforeSubmit(form.getValues());
        if (!ok) return;
      }
      await form.handleSubmit(props.onSubmit)();
    }
  );

  return (
    <FormProvider {...form}>
      <form
        className='w-full h-full flex flex-col md:flex-row'
        onSubmit={handleSubmit}
      >
        <div className='w-full md:w-80 p-6 md:p-10 bg-lp-gray-009'>
          <Controller<EventEditorData, 'gamePackId'>
            name='gamePackId'
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <GamePackSelector
                featured={props.eventType === 'ond'}
                gamePackId={value}
                onChange={onChange}
                setGamePackPlayerRange={setGamePackPlayerRange}
                setCanAccessGamePack={setCanAccessGamePack}
                setSeatCapLimit={setSeatCapLimit}
                orgName={
                  props.eventType === 'live'
                    ? organizer?.organization?.name ?? orgName
                    : undefined
                }
                title={
                  props.eventType === 'live'
                    ? 'Choose Game Pack'
                    : 'Choose Experience'
                }
                modalTitle={
                  props.eventType === 'live'
                    ? 'Select the Game Pack for this Event'
                    : 'Choose from our Featured Games'
                }
                modalTips={
                  props.eventType === 'live'
                    ? 'Select a Game Pack for this live experience.'
                    : 'These are some recommendations. Don’t worry, you can always change this later and play as many games as you’d like.'
                }
                modalClassName={props.eventType === 'live' ? 'w-160' : 'w-3/4'}
              />
            )}
          />
        </div>

        <div className='flex-1 p-10 pb-0'>
          <header className=' text-white font-bold text-3xl mb-12 flex flex-col'>
            <div className='flex flex-row justify-between'>
              <div className='xl:w-3/4'>{props.title}</div>
              {props.eventType === 'live' && props.context === 'create' && (
                <div className='flex flex-row text-xs font-normal items-center gap-2 h-3'>
                  <div className='w-23'>Non-Subscriber</div>
                  <SwitcherControlled
                    name='host-autogain'
                    checked={isSubscriber}
                    onChange={(checked) => {
                      setValue<'orgName'>('orgName', undefined);
                      setValue<'organizerName'>('organizerName', undefined);
                      setValue<'organizerEmail'>('organizerEmail', undefined);
                      setValue<'organizerUid'>('organizerUid', null);
                      setValue<'organizer'>('organizer', undefined);
                      setValue<'attendees'>('attendees', []);
                      setValue<'attendeeEmails'>('attendeeEmails', undefined);
                      setIsSubscriber(checked);
                    }}
                  />
                  <div>Subscriber</div>
                </div>
              )}
            </div>
            <p className='mt-2 text-icon-gray text-base font-normal xl:w-3/4'>
              {props.subtitle}
            </p>
          </header>

          <div className='xl:w-3/4'>
            {props.eventType === 'live' ? (
              <LiveEventsFields
                isSubscriber={isSubscriber}
                initialData={props.initialData}
                extraFormFields={props.extraFormFields}
                context={props.context}
                eventId={props.eventId}
              />
            ) : (
              <OnDemandEventsFields
                gamePackPlayerRange={gamePackPlayerRange}
                extraFormFields={props.extraFormFields}
                seatCapLimit={seatCapLimit}
                presetEventTime={Boolean(
                  props.context === 'create' && props.initialData?.eventTime
                )}
              />
            )}

            <div className='pb-10'>
              <EventEditorButtons
                context={props.context}
                canAccessGamePack={canAccessGamePack}
                exceedsSeatCapLimit={
                  attendees.length + 1 >
                  (seatCapLimit ?? Number.POSITIVE_INFINITY)
                }
                onCancel={props.onCancel}
                canSubmit={props.canSubmit ?? true}
                showSendNotify={
                  props.eventType === 'live' && props.context === 'edit'
                }
                sendNotify={props.initialData?.sendLiveNotify}
                updateSendNotify={(b) => {
                  setValue<'sendLiveNotify'>('sendLiveNotify', b);
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
